import React from "react";
import { observer, inject } from "mobx-react";

import NewAverageChart from "./charts/NewAverageChart";
import NewPieChart from "./charts/NewPieChart";
import NewBarChart from "./charts/NewBarChart";
import OtherAnswers from "./OtherAnswers";
import { ChartItemContainer } from "../uikit/Chart";
import CurveChart from "./charts/CurveChart";

class ChartItem extends React.Component {
  renderChart(chart) {
    switch (chart.chartType) {
      case "curve":
        return <CurveChart data={chart.data} chart={chart} />;
      case "average":
        return <NewAverageChart data={chart.data} chart={chart} />;
      case "piechart":
        return <NewPieChart data={chart.data} chart={chart} />;
      case "barchart":
        return <NewBarChart data={chart.data} chart={chart} />;
      default:
        return <p>None</p>;
    }
  }

  render() {
    //console.log(this.props);
    const { title, question } = this.props;

    return (
      <ChartItemContainer>
        <h2>
          {title ? title : question.title}
          <span>({question.filteredAnswers.length})</span>
        </h2>
        {this.renderChart(question.chart)}
        {question.other !== "" && <OtherAnswers question={question} />}
      </ChartItemContainer>
    );
  }
}

export default inject("store")(observer(ChartItem));

/*
<ul>
          {question.chartList.map(cl => (
            <li key={cl.chartType}>
              <a
                href="#0"
                onClick={e => {
                  e.preventDefault();
                  question.activeChartType = cl.chartType;
                }}
              >
                {cl.chartType}
              </a>
            </li>
          ))}
        </ul>
        */
