import { decorate, observable, computed } from "mobx";
import Chart from "./Chart";
import Filter from "./Filter";

class Question {
  store;
  id;
  key;
  altKeys = [];
  format;
  charts = new Map();
  filterable = false;
  sorting = null;
  filters = [];
  scoring = false;

  activeChartType;
  answers = [];
  cutOff = null;
  other = "";
  selectedOther = [];
  range = null;
  _title = null;

  constructor(key, conf, store) {
    this.key = key;
    this.id = conf.id + "";
    this.store = store;
    if (conf.merge) {
      this.altKeys = conf.merge;
    }
    this.format = conf.format;
    this.filterable = conf.filterable;
    if (conf.sorting) {
      this.sorting = conf.sorting;
    }
    if (this.filterable) {
      conf.charts.forEach((chartType) => {
        this.charts.set(chartType, new Chart(chartType, this, store.cruncher));
      });

      this.activeChartType = conf.charts[0];
    }
    if (conf.title) this._title = conf.title;
    if (conf.scoring) this.scoring = true;
    if (conf.cutOff) {
      console.log("Cut", conf.cutOff);
      this.cutOff = conf.cutOff;
    }
    if (conf.other) this.other = conf.other;
    if (conf.range) {
      this.range = conf.range;
    }
  }

  matchesKey(key) {
    if (this.key === key) return true;
    if (this.altKeys.length) {
      return this.altKeys.includes(key);
    }
    return false;
  }

  get filteredAnswers() {
    return this.store.filteredAnswers.filter((a) => a.questionId === this.id);
  }

  get activeFilters() {
    return this.filters.filter((f) => f.active);
  }

  get chart() {
    return this.charts.get(this.activeChartType);
  }

  get title() {
    if (this._title) return this._title;

    return this.key.replace(/(<([^>]+)>)/gi, "");
  }

  get chartList() {
    return Array.from(this.charts.values());
  }

  get hasFilters() {
    return this.activeFilters.length !== 0;
  }

  get selectedUserFilterValue() {
    let f = this.filters.find((f) => f.type === "user");
    if (f) return f.d.join(",");
    return "0";
  }

  isActive(d) {
    return this.hasFilter(d);
  }

  hasFilter(d) {
    let filter = this.activeFilters.find((f) => f.matchesData(d));
    if (filter) return true;
    return false;
  }

  removeFilter(d) {
    let f = this.filters.find((f) => f.matchesData(d));
    if (f) {
      this.filters.remove(f);
    }
  }

  addFilter(filter) {
    if (!this.hasFilter(filter.d)) {
      this.filters.push(filter);
    }
  }

  toggleFilter(d) {
    console.log("Filter", d);
    if (this.hasFilter(d)) {
      this.removeFilter(d);
    } else {
      this.addFilter(new Filter(this, d));
    }
  }

  addAnswer(a) {
    this.answers.push(a);
  }

  get filteredAnswerCount() {
    return this.filteredAnswers.length;
  }

  makeCutoff() {
    if (this.cutOff === null) return;

    let result = {};

    this.answers.forEach((a) => {
      if (result[a.value] === undefined) {
        result[a.value] = {
          answers: [a],
          score: 1
        };
      } else {
        result[a.value].answers.push(a);
        result[a.value].score++;
      }
    });

    Object.entries(result).forEach((entry) => {
      let v = entry[1];

      if (v.score <= this.cutOff) {
        v.answers.forEach((a) => {
          a.value = "Overig";
        });
      }
    });
  }

  addUserFilter(ids, title) {
    console.log("Adding user filter", ids, title);
    this.addFilter(new Filter(this, ids, "user", title));
  }

  removeUserFilter() {
    let f = this.filters.find((f) => f.type === "user");
    if (f) {
      this.filters.remove(f);
    }
  }
}

export default decorate(Question, {
  filters: observable,
  activeChartType: observable,
  filteredAnswers: computed,
  activeFilters: computed,
  chart: computed,
  title: computed,
  chartList: computed,
  hasFilters: computed,
  filteredAnswerCount: computed,
  selectedUserFilterValue: computed,
  selectedOther: observable
});
