import styled from "styled-components";

export const Nav = styled.div`
  background-color: ${(props) => props.theme.gray06};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: transparent;
    color: ${(props) => props.theme.gray02};
    border: 1px solid ${(props) => props.theme.gray04};
    border-radius: 6px;
    height: 32px;

    &:hover {
      border-color: ${(props) => props.theme.mainColor};
      color: ${(props) => props.theme.mainColor};
    }
  }
`;

export const LeftNav = styled.div`
  flex: 0 0 260px;
  display: flex;
  align-items: stretch;
  height: 50px;
  overflow: hidden;
`;

export const CenterNav = styled.div`
  flex: 1 1 auto;
`;

export const RightNav = styled.div`
  flex: 0 0 200px;
  display: flex;
  button {
    margin-right: 4px;
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  label {
    color: ${(props) => props.theme.gray02};
    font-size: 12px;
    margin-top: 2px;
    margin-right: 10px;
  }
`;
